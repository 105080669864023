<script>
import Logo from '@app/components/Logo.vue';

export default {
  name: 'AuthLayout',
  components: {
    Logo,
  },
};
</script>

<template>
  <div class="alcomp py-3 has-background-light">
    <div class="alcomp_wrap container">
      <div v-if="$store.getters['auth/user']" class="alcomp_notif notification is-warning">
        Attention ! Vous êtes déjà connecté.
        <router-link class="flex-none button is-white" :to="{name: 'home'}">
          Retourner à mon espace
        </router-link>
      </div>

      <header class="py-6 px-4">
        <nav class="level is-mobile">
          <div class="level-left">
            <Logo />
          </div>
          <div class="level-right">
            <slot name="menu" />
          </div>
        </nav>
      </header>

      <main class="section py-1">
        <div class="columns is-vcentered h-full is-desktop">
          <div class="column is-5-desktop">
            <div class="box">
              <slot />
            </div>
          </div>
          <div class="column is-6-desktop is-offset-1-desktop has-text-centered">
            <figure class="is-hidden-touch">
              <slot name="figure" />
            </figure>
            <h2 class="title is-4 mt-10">
              <slot name="title" />
            </h2>
            <p class="has-text-link">
              <slot name="description" />
            </p>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.alcomp {
  min-height: 100vh;

  &_notif {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @include touch {
    .column {
      max-width: 640px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @include mobile {
    &_notif {
      display: block;
      margin-top: pxToRem(-12px);
      padding: pxToRem(20px);
      text-align: center;
      border-radius: 0;

      .button {
        margin-top: 12px;
        width: 100%;
      }
    }

    .box {
      padding: 0;
    }
  }
}
</style>
