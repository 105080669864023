<script>
import AuthLayout from '@app/layouts/AuthLayout.vue';
import RegisterForm from '@app/components/auth/RegisterForm.vue';

export default {
  name: 'Register',
  components: { AuthLayout, RegisterForm },
  head: {
    title: 'Inscription',
  },
};
</script>

<template>
  <AuthLayout class="page">
    <template #menu>
      <router-link to="/connexion">
        Connexion
      </router-link>
    </template>

    <template #title>
      Rejoignez la communauté dès aujourd’hui
    </template>
    <template #description>
      Teachizy c'est : Zéro installation, des mises à jour gratuites régulières, sans aucun engagement, des transactions sécurisées et le tout, par une <strong class="has-text-link">entreprise 100% Française</strong>.
    </template>

    <template #figure>
      <BaseLazyImg
        class="w-full"
        :src="require('@app/assets/img/illus/teachizy_communaute.png')"
        :src-low="require('@app/assets/img/illus/teachizy_communaute.png?lqip')"
        :src-webp="require('@app/assets/img/illus/teachizy_communaute.png?webp')"
      />
    </template>

    <RegisterForm />
  </AuthLayout>
</template>
