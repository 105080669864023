<script>
export default {
  props: ['counter'],
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = window.turnstile.render(this.$refs.turnstile, {
      sitekey: this.$env.turnstileKey,
      language: 'fr',
      callback: (token) => {
        this.$emit('validated', token);
      },
      'error-callback': () => {
        //
      },
      theme: 'light',
    });
  },
  methods: {
    restart() {
      window.turnstile.reset(this.id);
    },
  },
  watch: {
    counter(newValue, oldValue) {
      if (oldValue < newValue) {
        this.restart();
      }
    },
  },
};
</script>

<template>
  <div class="turnstile" ref="turnstile"></div>
</template>
